import React from 'react'
import styled from '@emotion/styled'

import { isAuthenticated } from '../components/util/auth'
import { TextContainer, Text as BaseText } from '../components/common/text'
import SEO from '../components/seo'

const Text = styled(BaseText)`
	text-align: center;
`

export default () => {
	if (!isAuthenticated()) {
		return (
			<>
				<SEO title="To Login Page" description="Redirecting you to the login screen..." />
				<TextContainer>
					<Text>Redirecting you to the login screen...</Text>
				</TextContainer>
			</>
		)
	}
}
